import { mobileStyle } from '$gstyles/utils';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .cat {
    padding: 4px;
    .link {
      padding: 4px;
    }
  }

  .my-verse {
    max-width: 1200px;

    .verse-item {
      .label {
        width: 100px;
      }
      .content {
        flex: 1;
      }
    }
  }

  ${mobileStyle(`
    .page-wrapper {
      padding: 4px;
    }
  `)}
`;
