import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import PATH from '$business/enums/paths';
import { IonPageWrapper } from './styles';
import { sleep } from '$gbusiness/helpers/util';
import { bibleActions } from '$business/redux/bible';
import CurrentStateModel from '$business/models/currentState';
import { PageWrapper } from '$gstyles/wrapper';

import MainHeader from '$components/mainHeader';
import VerseList from '../chapterScreen/verseList';
import intl from '$gintl';

interface SearchScreenProps {
  history;
  onHydrate;
  match;
  chapter;
  onDehydrate;
  currentState: CurrentStateModel;
  verses;
}

const SearchScreen: React.FC<SearchScreenProps> = ({ history, match, chapter, verses, currentState }) => {
  const { params } = match;
  const { query } = params;

  useEffect(() => {
    console.log("SOMETHING CHANGED. LET's SEE what happens");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!chapter) return;
    goToChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  const goToChapter = async () => {
    await sleep(500);
    history.push(`${PATH.CHAPTER}/${chapter.book.id}/${chapter.chapter}`);
  };

  const title = intl('SCREEN.SEARCH.TITLE', { query: '"' + query + '"' });

  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} titleText={title} />
      <IonContent id="chap-content">
        <PageWrapper className="page-wrapper">
          {verses.length > 0 && <VerseList isSearch currentState={currentState} verses={verses} />}
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  verses: state.bible.searchVerses,
  chapter: state.bible.chapter,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: (params) => bibleActions.fetchSearch(params.query),
  onDehydrate: bibleActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SearchScreen));
