import { configs } from '$configs';
import { LAN_KEYS } from '../enums/options/lan';

export const getFactoryLogo = () => {
  const logoPath = configs.display.logoPath;
  return `${logoPath}`;
};

export const isKor = (currentState) => {
  const {
    settings: { lan },
  } = currentState;
  return lan === LAN_KEYS.KOR;
};

export const getNameKey = (currentState) => {
  if (isKor(currentState)) return 'kname';
  return 'ename';
};

export const getVerseKey = (currentState) => {
  if (isKor(currentState)) return 'kverse';
  return 'everse';
};
