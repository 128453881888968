import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { IonPageWrapper } from './styles';
import { bibleActions } from '$business/redux/bible';
import ChapterModel from '$business/models/chapter';
import CurrentStateModel from '$business/models/currentState';
import { PageWrapper } from '$gstyles/wrapper';

import VerseList from './verseList';
import { Desktop, Mobile } from '$gstyles';
import PATH from '$business/enums/paths';
import ChapButtons from './chapButtons';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import MainHeader from '$components/mainHeader';
import { scrollToId } from '$components/helper';
import usePrevious from '$gbox/hooks/usePrevious';

interface ChapterScreenProps {
  history;
  onHydrate;
  match;
  onDehydrate;
  currentState: CurrentStateModel;
  chapter: ChapterModel;
}

const ChapterScreen: React.FC<ChapterScreenProps> = ({ history, match, chapter, currentState }) => {
  const { params } = match;
  const { verseId: verseStr } = params;
  const verseId = parseInt(verseStr) || 0;
  const prevUid = usePrevious(chapter?.uid);

  useEffect(() => {
    if (prevUid || !chapter || !verseId) return;
    setTimeout(() => {
      if (!chapter) return;
      scrollToId('chap-content', 'v' + verseId);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter?.uid]);

  if (!chapter) return <IonPageWrapper />;
  const { chapter: chapterId, book } = chapter;
  const maxChapter = book.numChapters;

  const onSelectChapter = (chap) => {
    history.push(`${PATH.CHAPTER}/${chapter.book.id}/${chap}`);
  };

  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} chapter={chapter} />
      <Mobile zIndex={1}>
        <ChapButtons chapter={chapter} onSelectChapter={onSelectChapter} />
      </Mobile>
      <IonContent id="chap-content">
        <PageWrapper className="page-wrapper">
          <Desktop>
            <ChapButtons chapter={chapter} isDesktop onSelectChapter={onSelectChapter} />
            <div className="nav-buttons">
              {chapterId > 1 && (
                <ArrowBackIosNew onClick={() => onSelectChapter(chapterId - 1)} className="nav-button back" />
              )}
              {chapterId < maxChapter && (
                <ArrowForwardIos
                  onClick={() => onSelectChapter(chapterId + 1)}
                  className="nav-button forward"
                />
              )}
            </div>
          </Desktop>
          <VerseList selectedVerse={verseId} isChapter currentState={currentState} verses={chapter.verses} />
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  chapter: state.bible.chapter,
});

const mapDispatchToProps = {
  onHydrate: (params) => bibleActions.fetchChapter(params.bookId, params.chapId, params.verseId),
  onDehydrate: bibleActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ChapterScreen));
