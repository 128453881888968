import React, { useState } from 'react';
import { connect } from 'react-redux';
import { screen } from '$gcomponents/hoc';

import { BookItem, IonPageWrapper } from './styles';
import CurrentStateModel from '$business/models/currentState';

import { IonContent } from '@ionic/react';
import BookModel from '$business/models/book';
import { Flex } from '$gstyles/wrapper';
import { Row } from '$gstyles';
import ChapterSelector from './chapSelector';
import PATH from '$business/enums/paths';
import { getNameKey } from '$business/helpers/utils';
import MainHeader from '$components/mainHeader';

interface HomeScreenProps {
  currentState: CurrentStateModel;
  books: Array<BookModel>;
  history: any;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ books, history, currentState }) => {
  const [book, setBook] = useState<any>(null);
  const nameKey = getNameKey(currentState);
  const oldBooks = books.filter((b) => !b.isNew);
  const newBooks = books.filter((b) => b.isNew);

  const onCloseModal = () => {
    setBook(null);
  };
  const onChapSelect = (bookId, chapId) => {
    history.push(`${PATH.CHAPTER}/${bookId}/${chapId}`);
    onCloseModal();
  };

  const renderBook = (book, i) => {
    return (
      <BookItem className="book" onClick={() => setBook(book)} gridSize={3} key={i}>
        <div>{book[nameKey]}</div>
      </BookItem>
    );
  };

  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} />
      <IonContent>
        <Flex className="main-section">
          <div className="books old">
            <Row>{oldBooks.map(renderBook)}</Row>
          </div>
          <div className="books new">
            <Row>{newBooks.map(renderBook)}</Row>
          </div>
        </Flex>
      </IonContent>
      <ChapterSelector
        currentState={currentState}
        book={book}
        show={!!book}
        onChapSelect={onChapSelect}
        onClose={onCloseModal}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  books: state.bible.books,
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(HomeScreen));
