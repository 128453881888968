import UserModel from '$gbusiness/models/user';
import { LAN_KEYS } from '../enums/options/lan';

export default interface CurrentStateModel {
  isLoggedIn: boolean;
  user?: UserModel;
  settings: any;
}

export const initialCurrentState: CurrentStateModel = {
  isLoggedIn: false,
  settings: {
    lan: LAN_KEYS.ENG,
    nameKey: 'ename',
    verseKey: 'everse',
    fontSize: 7,
  },
};
