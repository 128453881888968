import React, { useEffect, useState } from 'react';
import VerseModel from '$business/models/verse';
import CurrentStateModel from '$business/models/currentState';
import { Flex } from '$gstyles/wrapper';
import { isKor } from '$business/helpers/utils';
import { Desktop, Div } from '$gstyles';
import { VerseListWrapper } from './styles';
import { verseLink } from '$components/helper';
import { Checkbox } from '@mui/material';
import FabButton from '$components/fabButton';
import AddVerseModal from './addVerseModal';
import { useLongPress } from 'use-long-press';
import { intToArray } from '$gbusiness/helpers/util';

interface VerseListProps {
  verses: Array<VerseModel>;
  currentState: CurrentStateModel;
  selectedVerse?: number;
  isChapter?: boolean;
  isSearch?: boolean;
}

const VerseList: React.FC<VerseListProps> = ({
  verses,
  currentState,
  isChapter = false,
  isSearch = false,
  selectedVerse = 0,
}) => {
  const [selectMode, setSelectMode] = useState(false);
  const [checks, setChecks] = useState<any>(intToArray(verses.length).map((v) => false));
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    setChecks(intToArray(verses.length).map((v) => false));
    setSelectMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verses.length]);

  const kor = isKor(currentState);
  const checkVerse = (index) => {
    const newChecks = checks.map((v, i) => (i === index ? !v : v));
    setChecks(newChecks);
    if (!newChecks.find((m) => m)) setSelectMode(false);
    else setSelectMode(true);
  };

  const backspaceLongPress = useLongPress(
    (e, { context: index }) => {
      checkVerse(index);
    },
    { cancelOnMovement: true, threshold: 800 },
  );

  const onAddVerse = () => {
    setShowAddModal(true);
  };

  const onCloseModal = () => {
    setShowAddModal(false);
  };

  const onAddOrRemove = () => {
    setChecks(intToArray(verses.length).map((v) => false));
    onCloseModal();
  };

  const renderVerseItem = (verse, lan, i) => {
    let Outer;
    if (kor && lan !== 'k') Outer = Desktop;
    else if (!kor && lan === 'k') Outer = Desktop;
    else Outer = Div;
    return (
      <Outer className={`verse-item ${lan}`} {...backspaceLongPress(i)}>
        {isChapter ? (
          <div className="label">{verse.verse}</div>
        ) : (
          <div className="label">
            {verseLink(verse, `${lan === 'e' ? verse.ename : verse.kab} ${verse.chapterId}:${verse.verse}`)}
          </div>
        )}
        <div className="content">{verse[lan + 'verse']}</div>
      </Outer>
    );
  };

  return (
    <VerseListWrapper isChapter={isChapter}>
      {verses.map((verse, i) => {
        return (
          <Flex
            className={`verse-line ${verse.myverse && 'myverse'} ${
              selectedVerse === verse.verse && 'selected'
            }`}
            key={i}
            id={`v${verse.verse}`}>
            {currentState.isLoggedIn && (
              <div className={`check-container ${(isChapter || isSearch) && selectMode ? 'show' : 'hide'}`}>
                <Checkbox checked={checks[i]} onChange={() => checkVerse(i)} />
              </div>
            )}
            {kor ? (
              <>
                {renderVerseItem(verse, 'k', i)}
                {renderVerseItem(verse, 'e', i)}
              </>
            ) : (
              <>
                {renderVerseItem(verse, 'e', i)}
                {renderVerseItem(verse, 'k', i)}
              </>
            )}
          </Flex>
        );
      })}
      {checks.find((m) => m) && (isChapter || isSearch) && <FabButton onClick={onAddVerse} />}
      <AddVerseModal
        isChapter
        currentState={currentState}
        show={showAddModal}
        onAddOrRemove={onAddOrRemove}
        onClose={onCloseModal}
        verses={verses.filter((v, i) => checks[i])}
      />
    </VerseListWrapper>
  );
};

export default VerseList;
