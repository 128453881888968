import React from 'react';
import { IonContent } from '@ionic/react';

import BookModel from '$business/models/book';
import { ModalWrapper } from './styles';
import { intToArray } from '$gbusiness/helpers/util';
import CurrentStateModel from '$business/models/currentState';
import { getNameKey } from '$business/helpers/utils';

interface ChapterSelectorProps {
  book: BookModel;
  show: boolean;
  onClose: Function;
  onChapSelect: Function;
  currentState: CurrentStateModel;
}

const ChapterSelector: React.FC<ChapterSelectorProps> = ({
  book,
  currentState,
  show,
  onClose,
  onChapSelect,
}) => {
  const nameKey = getNameKey(currentState);
  const renderChapButton = (i) => {
    const chap = i + 1;
    return (
      <div key={i} onClick={() => onChapSelect(book.id, chap)} className="chap-button">
        {chap}
      </div>
    );
  };
  const title = book ? book[nameKey] : '';

  return (
    <ModalWrapper width="540px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <IonContent>
        {book && <div className="grid-view">{intToArray(book.numChapters).map(renderChapButton)}</div>}
      </IonContent>
    </ModalWrapper>
  );
};

export default ChapterSelector;
