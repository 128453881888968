import { IonButtons, IonMenuToggle } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, AccountCircle, LockOpen, Home, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { IonHeaderWrapper, SearchBarWrapper } from './styles';
import { Autocomplete } from '$gcomponents/reusables';
import { configs, features } from '$configs';
import { MobileOver } from '$gstyles/wrapper';
import { Flex } from '$gstyles/wrapper';
import PopOver from '$gcomponents/reusables/popOver';
import intl from '$gintl';
import LoginModal from '../loginModal';
import { loadingActions } from '$gbusiness/redux/loading';
import CurrentStateModel from '$business/models/currentState';
import { useHistory } from 'react-router';
import { Div, SPACE, Desktop, Mobile } from '$gstyles';
import PATH from '$business/enums/paths';
import LanSelector from './lanSelector';
import { Link } from '$gcomponents/primitives';
import ChapterModel from '$business/models/chapter';
import AccountMenu from './accountMenu';
import { getNameKey } from '$business/helpers/utils';
import SettingModal from './settingModal';
import { sleep } from '$gbusiness/helpers/util';
import { getEl } from '$gbusiness/helpers/input';

interface MainHeaderProps {
  logo?: string;
  currentState: CurrentStateModel;
  chapter?: ChapterModel;
  titleText?: string;
  titleRoute?: string;
}

export const AUTO_COMPLETE = (currentState, history) => {
  const nameKey = getNameKey(currentState);
  return {
    method: 'POST',
    minChar: 2,
    renderSuggestion: (e) => {
      return (
        <Flex>
          <Div marginLeft={SPACE.SMALL}>{`${e[nameKey]}${e.chapter ? `, ${e.chapter}` : ', '}`}</Div>
        </Flex>
      );
    },
    getLabel: (e) => {
      return `${e[nameKey]}${e.chapter ? `, ${e.chapter}` : ', '}`;
    },
    onEnter: (e) => {
      if (!e) return;
      history.push(PATH.SERACH + '/' + e);
    },
    deriveRaw: (response) => {
      return [...response.list];
    },
  };
};

const MainHeader: React.FC<MainHeaderProps> = ({ currentState, titleText, logo, titleRoute, chapter }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { settings } = currentState;
  const title =
    titleText ||
    (chapter
      ? intl('SCREEN.HOME.CHAPTER_TITLE', {
          book: chapter.book[settings.nameKey],
          chapter: chapter.chapter,
        })
      : intl('SCREEN.HOME.TITLE'));
  const { isLoggedIn } = currentState;
  const onClickLogin = () => {
    dispatch(loadingActions.showLoginModal());
  };

  const onClickSetting = () => {
    setShowSetting(true);
  };

  const toggleSearchMode = async (value = null) => {
    if (value) setSearchMode(value);
    else setSearchMode(!searchMode);

    if (!searchMode) {
      await sleep(100);
      const inputEl = getEl('.search-header .ac-wrapper input');
      if (inputEl) inputEl.focus();
    }
  };
  const SideMenuToggleIcon = (
    <>
      <IonMenuToggle menu="start" className="hide">
        <IconButton className="link">
          <Menu />
        </IconButton>
      </IonMenuToggle>
      <Link route="/">
        <IconButton>
          <Home />
        </IconButton>
      </Link>
    </>
  );
  const AccountMenuToggleIcon = isLoggedIn ? (
    <PopOver
      className="icon-button link"
      component={
        <Flex>
          <AccountCircle fontSize="large" />
          <MobileOver className="label">{intl('COMMON.MY_ACCOUNT')}</MobileOver>
        </Flex>
      }>
      <AccountMenu onClickSetting={onClickSetting} />
    </PopOver>
  ) : features.hideLogin ? null : (
    <div className="link uppercase login" onClick={onClickLogin}>
      <LockOpen fontSize="large" />
      {/* <div className="label">{intl('BUTTON.LOGIN')}</div> */}
    </div>
  );

  return (
    <>
      <IonHeaderWrapper id="sidemenu">
        <Flex justifyContent="space-between">
          <Flex>
            <IonButtons className="start" slot="start">
              {SideMenuToggleIcon}
            </IonButtons>
            <Flex className="title-section">
              {/* <div className="logo-section">
              <Link route="/">
                <Brand logo={logo} />
              </Link>
            </div> */}

              <h3>{titleRoute ? <Link route={titleRoute}>{title}</Link> : title}</h3>
            </Flex>
          </Flex>
          <Flex>
            <Desktop>
              <Flex className="search-wrapper">
                <Autocomplete
                  endpoint={configs.api.autocomplete}
                  AC={{
                    ...AUTO_COMPLETE(currentState, history),
                    onSelect: (value) => {
                      const path = `${PATH.CHAPTER}/${value.bookid}/${value.chapter ? value.chapter : 1}`;
                      history.push(path);
                    },
                  }}
                  width="300px"
                  height="32px"
                />
              </Flex>
            </Desktop>

            <IonButtons className="end" slot="end">
              <Mobile>
                <Search className="pointer" onClick={() => toggleSearchMode()} />
              </Mobile>
              <LanSelector currentState={currentState} />
              {AccountMenuToggleIcon}
            </IonButtons>
          </Flex>
          <LoginModal disableRegister={true} show={showLogin} onClose={() => setShowLogin(false)} />
        </Flex>
        <SettingModal currentState={currentState} show={showSetting} onClose={() => setShowSetting(false)} />
      </IonHeaderWrapper>
      {searchMode && (
        <SearchBarWrapper className="search-header">
          <Autocomplete
            endpoint={configs.api.autocomplete}
            AC={{
              ...AUTO_COMPLETE(currentState, history),
              onSelect: (value) => {
                const path = `${PATH.CHAPTER}/${value.bookid}/${value.chapter ? value.chapter : 1}`;
                history.push(path);
              },
            }}
            width="300px"
            height="32px"
          />
        </SearchBarWrapper>
      )}
    </>
  );
};

export default MainHeader;
