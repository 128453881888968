import BookModel, { deriveRawToBook } from './book';
import VerseModel, { deriveRawToVerse } from './verse';

export default interface ChapterModel {
  book: BookModel;
  chapter: number;
  verses: Array<VerseModel>;
  uid?: string;
}

export const deriveRawToChapter = (data) => {
  return {
    book: deriveRawToBook(data.book),
    chapter: data.chapter,
    verses: data.verses.map((m) => deriveRawToVerse(m)),
    uid: data.uid,
  };
};
