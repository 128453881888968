import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .cat {
    padding: 4px;
    display: flex;
    align-items: center;
    .link {
      padding: 4px;
    }
  }
  .delete-icon {
    font-size: 2rem;
    cursor: pointer;
  }
`;
