export const LAN_KEYS = {
  ENG: 'ENG',
  KOR: 'KOR',
};

const LIST = Object.keys(LAN_KEYS);

export default LIST.map((item) => ({
  label: `LAN.${item}`,
  value: item,
}));

export type LANG_TYPE = typeof LIST[number] | undefined;
