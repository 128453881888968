import {
  BibleModel,
  INIT_BIBLE,
  FETCH_BOOKS_SUCCESS,
  FETCH_CHAPTER_SUCCESS,
  SEARCH_SUCCESS,
  BIBLE_FAILURE,
  CLEAN_CHAPTER,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_SUCCESS,
} from './types';

export function bibleReducer(state: BibleModel = INIT_BIBLE, action): BibleModel {
  switch (action.type) {
    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.books,
      };
    case FETCH_CHAPTER_SUCCESS:
      return {
        ...state,
        chapter: action.chapter,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchVerses: action.verses,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
      };
    case BIBLE_FAILURE:
      return {
        ...state,
      };
    case CLEAN_CHAPTER:
      return {
        ...state,
        chapter: undefined,
      };
    default:
      return state;
  }
}
