import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import intl from 'react-intl-universal';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import Menu from '../../sideMenu';

import AppOutlet from '$product/_init/appOutlet';
import { getFactoryLogo } from '$business/helpers/utils';
import { IonReactRouter } from '@ionic/react-router';
import CurrentStateModel, { initialCurrentState } from '$business/models/currentState';
import UserModel from '$gbusiness/models/user';
import { LAN_KEYS } from '$business/enums/options/lan';
import { localStorageActions } from '$gbusiness/redux/localStorage';
import ChapterModel from '$business/models/chapter';
import { intToArray } from '$gbusiness/helpers/util';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
  setLocalStorage: Function;
  settings: any;
  chapter?: ChapterModel;
  user?: UserModel;
}

const Router: React.FC<RouterProps> = ({
  isLoggedIn,
  settings,
  currentRoute,
  chapter,
  user,
  setLocalStorage,
  onLogout,
}) => {
  const redirectRoute = ROUTES.DEFAULT_APP_PATH;
  const lan = settings?.lan || 'ENG';
  const fontSize = settings?.fontSize || 7;

  useEffect(() => {
    if (lan === LAN_KEYS.KOR) intl.load({ 'en-US': require('$intl/ko.json') });
    else intl.load({ 'en-US': require('$intl/en.json') });
    // force render after language change
    const chr = lan === LAN_KEYS.KOR ? 'k' : 'e';
    setLocalStorage('settings', { ...settings, nameKey: chr + 'name', verseKey: chr + 'verse' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lan]);

  useEffect(() => {
    const classes = intToArray(21).map((i) => 's' + (i + 1));
    document.body.classList.remove(...classes);
    document.body.classList.add('s' + fontSize);
  }, [fontSize]);

  const currentState: CurrentStateModel = {
    ...initialCurrentState,
    isLoggedIn,
    ...(settings && { settings }),
  };

  return (
    <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <Menu
        currentRoute={currentRoute}
        onLogout={onLogout}
        isLoggedIn={isLoggedIn}
        menuId="start"
        logo={getFactoryLogo()}
      />
      <IonContent>
        <IonContent>
          <AppOutlet currentState={currentState} user={user} />
          <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
        </IonContent>
      </IonContent>
    </IonReactRouter>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.localStorage.accessToken !== '',
  currentRoute: state.routing.currentRoute,
  settings: state.localStorage.settings,
  chapter: state.bible.chapter,
  user: state.currentUser,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
  setLocalStorage: localStorageActions.setObj,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
