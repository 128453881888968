import _ from 'lodash';
import React from 'react';
import { FastField, Field } from 'formik';

import intl from '$gintl';
import { BSLabel, Wrapper } from './styles';
import { configs } from '$configs';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextFieldProps } from '@mui/material';
import OutlinedDiv from './outlined';

export const generateRadioOptions = (list) =>
  list.map((o, i) => (
    <FormControlLabel
      value={o.value.toString()}
      control={<Radio />}
      label={o.labelText || intl('INPUT.OPTION.' + o.label)}
      key={i}
    />
  ));

interface RadioInputProps {
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  isMultiline?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  options: React.ReactNode | null;
  size?: string;
  handleChange?: (e) => void;
  disableFastField?: boolean;
}

const defaultProps: RadioInputProps = {
  label: '',
  labelText: '',
  name: '',
  formik: {},
  className: '',
  isMultiline: false,
  disabled: false,
  readonly: false,
  placeholder: '',
  position: undefined,
  options: null,
  size: configs.display.inputSize,
  handleChange: () => {},
  disableFastField: false,
};

const RadioButton: React.FC<RadioInputProps> = ({
  label,
  labelText,
  name,
  className,
  formik,
  disabled,
  isMultiline,
  readonly,
  placeholder,
  position,
  options,
  size,
  handleChange,
  disableFastField,
}) => {
  const onChange = (e) => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };
  const labelProps =
    position !== undefined
      ? {
          label: intl(label),
          InputLabelProps: {
            ...{ shrink: true },
          },
        }
      : {};
  const variant = position || 'outlined';
  const inputSize = size === 'small' ? 'small' : 'medium';
  const radioOptions = generateRadioOptions(options);
  const FormField = disableFastField ? Field : FastField;

  return (
    <Wrapper isMultiline={isMultiline} size={inputSize} className={`radio-group ${className}`}>
      <FormField name={name}>
        {({ form }) => {
          const value = _.get(form.values, name) || '';
          return (
            <FormControl size={inputSize} variant={variant} fullWidth>
              {position === undefined && <BSLabel className="bs-label">{intl(label)}</BSLabel>}
              <OutlinedDiv labelProps={labelProps} variant={variant}>
                <RadioGroup name={name} value={value.toString()} onChange={onChange}>
                  {radioOptions}
                </RadioGroup>
              </OutlinedDiv>
            </FormControl>
          );
        }}
      </FormField>
    </Wrapper>
  );
};

RadioButton.defaultProps = defaultProps;

export default RadioButton;
