import PATH from '$business/enums/paths';
import { Link } from '$gcomponents/primitives';
import React from 'react';

export function verseLink(verse, text) {
  const { bookId, chapterId, verse: verseId } = verse;
  const route = `${PATH.CHAPTER}/${bookId}/${chapterId}/${verseId}`;
  return <Link route={route}>{text}</Link>;
}

export const scrollToId = (fromId, toId) => {
  const container: any = document.getElementById(fromId);
  if (!container) return;
  const destination = container.querySelector('#' + toId);
  if (!destination) return;
  container.scrollToPoint(0, destination.offsetTop);
};
