export default interface VerseModel {
  id: number;
  verse: number;
  kverse: string;
  everse: string;
  break?: boolean;
  bookId?: number;
  chapterId?: number;
  kname?: string;
  ename?: string;
  kab?: string;
  myverse?: boolean;
  cats?: Array<number>;
}

export const deriveRawToVerse = (raw, simple = false) => {
  return {
    id: raw.id,
    verse: raw.verse,
    kverse: raw.kverse,
    everse: raw.everse,
    break: raw.break === 1,
    ...(!simple && {
      bookId: raw.bookid,
      chapterId: raw.chapter,
      kname: raw.kname,
      ename: raw.ename,
      kab: raw.kab,
    }),
    myverse: raw.myverse ? true : false,
    ...(raw.cats && { cats: JSON.parse(raw.cats) }),
  };
};
