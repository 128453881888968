import React from 'react';
import intl from '$intl';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import PATH from '$business/enums/paths';
import { useDispatch } from 'react-redux';
import { authActions } from '$gbusiness/redux/auth';

interface AccountMenuProps {
  onClickSetting: Function;
}

const AccountMenu: React.FC<AccountMenuProps> = ({ onClickSetting }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const nav = (path = '/') => {
    history.push(path);
  };
  const logout = () => {
    dispatch(authActions.logout());
  };
  return (
    <div className="account-menu" style={{ width: '200px' }}>
      <Button className="left" fullWidth onClick={() => nav(PATH.MYVERSES)}>
        {intl('ACCOUNT.VERSES')}
      </Button>
      <Button className="left" fullWidth onClick={() => onClickSetting()}>
        {intl('SCREEN.SETTING.TITLE')}
      </Button>
      <Button className="left" fullWidth onClick={logout}>
        {intl('BUTTON.LOGOUT')}
      </Button>
    </div>
  );
};

export default AccountMenu;
