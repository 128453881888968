import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonImg,
} from '@ionic/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import intl, { Text } from '$intl';

import { Wrapper } from './styles';
import HomeIcon from '@mui/icons-material/Home';
import { accessString } from '$gbusiness/helpers/util';
import { Link } from '$gcomponents/primitives';

interface MenuProps extends RouteComponentProps {
  menuId: string;
  isLoggedIn: boolean;
  defaultExpanded?: boolean;
  logo?: string;
  currentRoute: string;
  onLogout: Function;
  hiddenDesktop?: boolean;
  hiddenMobile?: boolean;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({
    menuId,
    logo,
    defaultExpanded = false,
    hiddenDesktop,
    hiddenMobile,
    currentRoute,
    isLoggedIn,
    onLogout,
    ...rest
  }) => {
    const isDesktop = useMediaQuery(`(min-width:${configs.display.splitPaneThreshold})`);
    const isHidden =
      (hiddenDesktop && isDesktop) || (hiddenMobile && !isDesktop) || (!currentRoute && isDesktop);

    return (
      <Wrapper
        contentId="main"
        menuId={menuId}
        disabled={isHidden}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {!isDesktop && (
          <IonHeader>
            <IonToolbar>
              <IonMenuToggle>
                <Link route={ROUTES.DEFAULT_APP_PATH}>
                  <IonImg class="logo" src={logo || ''} />
                </Link>
              </IonMenuToggle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          <IonList>
            <IonMenuToggle autoHide={false} className="dept-item">
              <IonItem
                className={ROUTES.PATH.HOME === currentRoute ? 'selected pointer' : 'pointer'}
                routerLink={ROUTES.PATH.HOME}
                routerDirection="none"
                lines="none"
                detail={false}>
                <HomeIcon />
                <IonLabel>{intl('MENU.BACK_TO_ORDER')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <br />
            {ROUTES.ACCOUNT_LIST.map((appPage: any, index) => {
              if (!appPage.title) return null;
              return (
                <IonMenuToggle
                  key={index}
                  autoHide={false}
                  className={accessString(appPage.accessLevel, appPage.feature)}>
                  <IonItem
                    className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                    routerLink={`${appPage.path}${appPage.defaultId ? '/' + appPage.defaultId : ''}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}>
                    {appPage.icon && <appPage.icon />}
                    <IonLabel>
                      <Text k={appPage?.title} />
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              <ExitToAppIcon />
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
