import VerseModel, { deriveRawToVerse } from './verse';

export default interface CategoryModel {
  id: number;
  name: string;
  numVerses: number;
  verses?: Array<VerseModel>;
}

export const deriveRawToCategory = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    numVerses: raw.numVerses,
    ...(raw.verses && { verses: raw.verses.map((m) => deriveRawToVerse(m)) }),
  };
};
