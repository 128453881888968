import React from 'react';
import { IonFab, IonFabButton } from '@ionic/react';
import { Add } from '@mui/icons-material';
import { Link } from '$gcomponents/primitives';

interface FabButtonProps {
  onClick?: Function;
  color?: string;
  icon?: React.ReactNode;
  ver?: any;
  hor?: any;
  route?: string;
  isHidden?: boolean;
}

const FabButton: React.FC<FabButtonProps> = ({
  color = 'primary',
  ver = 'bottom',
  hor = 'end',
  icon,
  isHidden = false,
  onClick,
  route,
}) => {
  if (isHidden) return null;
  const props = {
    ...(onClick && { onClick: () => onClick() }),
  };
  const Button = (
    <IonFabButton color={color} {...props}>
      {icon || <Add />}
    </IonFabButton>
  );
  return (
    <IonFab vertical={ver} horizontal={hor} slot="fixed">
      {route ? <Link route={route}>{Button}</Link> : Button}
    </IonFab>
  );
};

export default FabButton;
