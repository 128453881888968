import React from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { ModalWrapper } from './styles';
import { Slider } from '@mui/material';
import CurrentStateModel from '$business/models/currentState';
import { PageWrapper } from '$gstyles/wrapper';
import { useDispatch } from 'react-redux';
import { localStorageActions } from '$gbusiness/redux/localStorage';

interface SettingModalProps {
  show: boolean;
  onClose: Function;
  currentState: CurrentStateModel;
}

const SettingModal: React.FC<SettingModalProps> = ({ show, onClose, currentState }) => {
  const dispatch = useDispatch();
  const { settings } = currentState;
  const fontSize = settings?.fontSize || 7;

  const onChangeSetting = async (e) => {
    if (e.target.value === fontSize) return;
    const newSettings = {
      ...settings,
      fontSize: e.target.value,
    };
    await dispatch(localStorageActions.setObj('settings', newSettings));
  };

  return (
    <ModalWrapper titleText={intl('SCREEN.SETTING.TITLE')} show={show} onClose={() => onClose()} useCustom>
      <IonContent>
        <PageWrapper>
          <div className="label">{intl('SCREEN.SETTING.FONT')}</div>
          <Slider value={fontSize} min={1} max={21} step={1} onChange={onChangeSetting} />
        </PageWrapper>
      </IonContent>
      {/* <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
          <Button onClick={onSubmit}>{intl('BUTTON.SUBMIT')}</Button>
        </Flex>
      </IonFooter> */}
    </ModalWrapper>
  );
};

export default SettingModal;
