import { combineReducers } from 'redux';

import { loadingReducer } from '$gbusiness/redux/loading';
import { routerReducer } from '$gbusiness/redux/router';
import { cuserReducer } from '$gbusiness/redux/currentUser';
import { userReducer } from '$gbusiness/redux/user';
import { toasterReducer } from '$gbusiness/redux/toaster';
import { localStorageReducer } from '$gbusiness/redux/localStorage';
import { bibleReducer } from './bible';

import { initReducer } from './init';

const appReducer = combineReducers({
  init: initReducer,
  loading: loadingReducer,
  localStorage: localStorageReducer,
  routing: routerReducer,
  currentUser: cuserReducer,
  user: userReducer,
  toaster: toasterReducer,
  bible: bibleReducer,
});

export default appReducer;
