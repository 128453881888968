import React from 'react';
import trans from '$intl';
import { Button, ButtonGroup } from '@mui/material';
import CurrentStateModel from '$business/models/currentState';
import { LAN_KEYS } from '$business/enums/options/lan';
import { useDispatch } from 'react-redux';
import { localStorageActions } from '$gbusiness/redux/localStorage';

interface LanSelectorProps {
  currentState: CurrentStateModel;
}

const LanSelector: React.FC<LanSelectorProps> = ({ currentState }) => {
  const dispatch = useDispatch();
  const { settings } = currentState;
  const { lan } = settings;

  const onSelect = async (key) => {
    const newSettings = {
      ...settings,
      lan: key,
    };
    await dispatch(localStorageActions.setObj('settings', newSettings));
  };

  return (
    <ButtonGroup color="primary" className="lan-selector" disableElevation variant="contained">
      <Button
        variant={lan === LAN_KEYS.ENG ? 'contained' : 'outlined'}
        onClick={() => onSelect(LAN_KEYS.ENG)}>
        {trans('INPUT.OPTION.LAN.ENG')}
      </Button>
      <Button
        variant={lan === LAN_KEYS.KOR ? 'contained' : 'outlined'}
        onClick={() => onSelect(LAN_KEYS.KOR)}>
        {trans('INPUT.OPTION.LAN.KOR')}
      </Button>
    </ButtonGroup>
  );
};

export default LanSelector;
