export default interface BookModel {
  id: number;
  ename: string;
  kname: string;
  numChapters: number;
  isNew: boolean;
}

export const deriveRawToBook = (raw) => {
  return {
    id: raw.bookid,
    ename: raw.ename,
    kname: raw.kname,
    numChapters: raw.numchapters,
    isNew: raw.testament === 'new',
  };
};
