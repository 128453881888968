import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import CurrentStateModel from '$business/models/currentState';
import VerseList from './verseList';
import VerseModel from '$business/models/verse';
import NativeSelect from '$gcomponents/primitives/selectInput/nativeSelect';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import { intToArray } from '$gbusiness/helpers/util';
import { ModalWrapper } from './styles';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { bibleActions } from '$business/redux/bible';
import { dialog } from '$gcomponents/reusables';

interface AddVerseModalProps {
  show: boolean;
  onClose: Function;
  onAddOrRemove?: Function;
  currentState: CurrentStateModel;
  verses: Array<VerseModel>;
  isChapter?: boolean;
}

const AddVerseModal: React.FC<AddVerseModalProps> = ({
  show,
  onClose,
  isChapter = false,
  currentState,
  onAddOrRemove,
  verses,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state.bible.categories);
  const initialCats = verses[0]?.cats || [0, 0, 0, 0, 0];
  const [cats, setCats] = useState<Array<number>>(initialCats);
  const [newCat, setNewCat] = useState('');

  useEffect(() => {
    setCats(verses[0]?.cats || [0, 0, 0, 0, 0]);
  }, [verses]);

  const onFinish = () => {
    if (onAddOrRemove) onAddOrRemove();
    else onClose();
  };

  const onSubmit = async () => {
    const verseIds = verses.map((m) => m.id);
    await dispatch(bibleActions.addMyVerse(verseIds, cats));
    if (isChapter) dispatch(bibleActions.fetchChapter(verses[0].bookId, verses[0].chapterId));
    onFinish();
  };

  const removeVerse = async () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: async () => {
        const verseIds = verses.map((m) => m.id);
        await dispatch(bibleActions.removeVerse(verseIds));
        if (isChapter) dispatch(bibleActions.fetchChapter(verses[0].bookId, verses[0].chapterId));
        onFinish();
      },
    });
  };

  const options = insertEmptyOption(
    categories.map((c) => ({ labelText: c.name, value: c.id })),
    'None',
  );

  const onChangeCat = (value, index) => {
    setCats(cats.map((m, i) => (index === i ? value : m)));
  };

  const onAddNewCat = async () => {
    await dispatch(bibleActions.addCat(newCat));
    setNewCat('');
  };

  return (
    <ModalWrapper width="800px" title="ACCOUNT.ADD_VERSE" show={show} onClose={() => onClose()} useCustom>
      <IonContent>
        <PageWrapper>
          {show && (
            <>
              <VerseList currentState={currentState} verses={verses} />
              <br />
              {intToArray(5).map((i) => (
                <NativeSelect
                  key={i}
                  className="select-input"
                  label={intl('ACCOUNT.CATEGORY', { num: i + 1 })}
                  size="small"
                  onChange={(value) => onChangeCat(value, i)}
                  options={options}
                  value={cats[i]}
                />
              ))}
              <Flex>
                <TextField
                  size="small"
                  className="new-input"
                  label={intl('ACCOUNT.NEW_CATEGORY')}
                  value={newCat}
                  onChange={(e) => setNewCat(e.target.value)}
                />
                <Button size="small" onClick={onAddNewCat}>
                  {intl('BUTTON.ADD')}
                </Button>
              </Flex>
            </>
          )}
        </PageWrapper>
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="space-around">
          <Button className="third" color="warning" onClick={removeVerse}>
            {intl('BUTTON.REMOVE_VERSE')}
          </Button>
          <Button className="half" onClick={onSubmit}>
            {intl('BUTTON.ADD_VERSE')}
          </Button>
        </Flex>
      </IonFooter>
    </ModalWrapper>
  );
};

export default AddVerseModal;
