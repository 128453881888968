export const PATH = {
  HOME: '/home',
  CHAPTER: '/chapter',
  MENU: '/category',
  MYVERSES: '/myverses',
  MYVERSE: '/myverse',
  INVENTORY: '/inventory',
  SERACH: '/search',
};

export default PATH;
