import BookModel from '../../models/book';
import CategoryModel from '../../models/category';
import ChapterModel from '../../models/chapter';
import VerseModel from '../../models/verse';

export const FETCH_BOOKS_SUCCESS = 'FETCH_BOOKS_SUCCESS';
export const FETCH_CHAPTER_SUCCESS = 'FETCH_CHAPTER_SUCCESS';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const BIBLE_FAILURE = 'BIBLE_FAILURE';
export const CLEAN_CHAPTER = 'CLEAN_CHAPTER';

// Action Types
export interface CleanChapType {
  type: typeof CLEAN_CHAPTER;
}
export interface FetchBooksSuccess {
  type: typeof FETCH_BOOKS_SUCCESS;
  books: Array<BookModel>;
}

export interface FetchCategoriesSuccess {
  type: typeof FETCH_CATEGORIES_SUCCESS;
  categories: Array<CategoryModel>;
}

export interface FetchCategorySuccess {
  type: typeof FETCH_CATEGORY_SUCCESS;
  category: CategoryModel;
}

export interface FetchChapterSuccess {
  type: typeof FETCH_CHAPTER_SUCCESS;
  chaper: ChapterModel;
}

export interface SearchSuccessType {
  type: typeof SEARCH_SUCCESS;
  verses: Array<VerseModel>;
}
export interface BibleFailureType {
  type: typeof BIBLE_FAILURE;
  err: string;
}

export interface BibleModel {
  books?: Array<BookModel>;
  chapter?: ChapterModel;
  searchVerses: Array<VerseModel>;
  query?: string;
  categories: Array<CategoryModel>;
  category?: CategoryModel;
}

export const INIT_BIBLE: BibleModel = {
  books: [],
  chapter: undefined,
  searchVerses: [],
  query: '',
  categories: [],
};
