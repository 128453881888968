import React from 'react';
import { IonRouterOutlet } from '@ionic/react';

import { ROUTES } from '$business/enums';
import { canAccess } from '$gbusiness/helpers/util';
import CurrentStateModel from '$business/models/currentState';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import UserModel from '$gbusiness/models/user';

interface RouterProps {
  currentState: CurrentStateModel;
  user?: UserModel;
}

const AppOutlet: React.FC<RouterProps> = ({ currentState, user }) => {
  return (
    <IonRouterOutlet id="main" animated={false}>
      {ROUTES.PRIVATE_LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          user={user}
          exact={menu?.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          currentState={currentState}
          feature={menu.feature}
          isAccessible={canAccess(menu.accessLevel, user?.userLevel)}
        />
      ))}
      {ROUTES.PUBLIC_LIST.map((menu, i) => (
        <PublicRoute
          key={i}
          user={user}
          currentState={currentState}
          path={`${menu?.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}
    </IonRouterOutlet>
  );
};

export default AppOutlet;
