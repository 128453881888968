import { Dispatch } from 'redux';

import { apiService } from '$gbusiness/services';

import {
  BIBLE_FAILURE,
  CLEAN_CHAPTER,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CHAPTER_SUCCESS,
  SEARCH_SUCCESS,
} from './types';
import { configs } from '$configs';
import UserModel, { defaultUser, deriveRawToUser, LoginModel } from '$gbusiness/models/user';
// import { handleApiFail } from '$gbusiness/services/api';

import { getPublicFactoryId } from '$gbusiness/helpers/util';
import { LOGGING_IN, LOGIN_SUCCESS } from '$gbusiness/redux/auth/types';
import { handleFail, storeAuth } from '$gbusiness/redux/auth/actions';
import { LOAD_CARDS_SUCCESS } from '$gbusiness/redux/card/types';
import { handleApiFail } from '$gbusiness/services/api';
import VerseModel, { deriveRawToVerse } from '../../models/verse';
import { LOADING } from '$gbusiness/redux/loading/types';
import ChapterModel, { deriveRawToChapter } from '../../models/chapter';
import CategoryModel, { deriveRawToCategory } from '../../models/category';

export function fetchChapter(bookId, chapId, verseId = 0): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: `${configs.api.chapter}?bid=${bookId}&cid=${chapId}&vid=${verseId}`,
      param: {},
      method: 'GET',
    });

    if (!response || !response.data) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const chapter: ChapterModel = deriveRawToChapter(response.data);

    dispatch({
      type: FETCH_CHAPTER_SUCCESS,
      chapter,
    });
  };
}

export function fetchSearch(query): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.search,
      param: {
        q: query,
      },
      method: 'POST',
    });

    if (!response || (!response.verses && !response.data)) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    if (response.verses) {
      const verses: Array<VerseModel> = response.verses.map((m) => deriveRawToVerse(m, false));
      dispatch({
        type: SEARCH_SUCCESS,
        verses,
      });
    }
    if (response.data) {
      const chapter: ChapterModel = deriveRawToChapter(response.data);

      dispatch({
        type: FETCH_CHAPTER_SUCCESS,
        chapter,
      });
    }
  };
}

export function getMyCategories(): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.categories,
      param: {},
      method: 'GET',
    });

    if (!response || !response.cats) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const categories: Array<CategoryModel> = response.cats.map(deriveRawToCategory);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
    });
  };
}

export function getMyCategory(id): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.myverses + '?id=' + id,
      param: {},
      method: 'GET',
    });

    if (!response || !response.cat) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const category: Array<CategoryModel> = deriveRawToCategory(response.cat);

    dispatch({
      type: FETCH_CATEGORY_SUCCESS,
      category,
    });
  };
}

export function addMyVerse(verses, cats): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.addverse,
      param: {
        verses,
        cats,
      },
      method: 'POST',
    });

    if (!response || !response.success) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const categories: Array<CategoryModel> = response.cats.map(deriveRawToCategory);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
    });
  };
}

export function removeVerse(verses): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.removeVerse,
      param: {
        verses,
      },
      method: 'POST',
    });

    if (!response || !response.success) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const categories: Array<CategoryModel> = response.cats.map(deriveRawToCategory);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
    });
  };
}

export function addCat(name): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.addCat,
      param: {
        name,
      },
      method: 'POST',
    });

    if (!response || !response.success) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const categories: Array<CategoryModel> = response.cats.map(deriveRawToCategory);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
    });
  };
}

export function removeCat(id): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({ type: LOADING, loadingText: 'PROGRESS.INITIALIZING' });
    const response = await apiService.fetchApi({
      url: configs.api.removeCat,
      param: {
        id,
      },
      method: 'POST',
    });

    if (!response || !response.success) {
      handleApiFail(dispatch, BIBLE_FAILURE, 'SERVER.ERROR', '');
      return;
    }

    const categories: Array<CategoryModel> = response.cats.map(deriveRawToCategory);

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories,
    });
  };
}

export function login(param: LoginModel): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await apiService.fetchApi({
      url: configs.api.login,
      param: {
        ...param,
        factoryId: getPublicFactoryId(),
      },
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.user || !response.accessToken) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    dispatch({
      type: LOGIN_SUCCESS,
      accessToken: response.accessToken,
      user,
    });

    dispatch({
      type: LOAD_CARDS_SUCCESS,
      cards: response.cards,
    });
  };
}
export function dehydrate(): any {
  return { type: CLEAN_CHAPTER };
}
