import { ROUTES as GROUTES } from '$gbusiness/enums';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import { HomeOutlined } from '@mui/icons-material';
import { ChapterScreen, HomeScreen, MyVerseScreen, MyVersesScreen, SearchScreen } from '$product/screens';

// IMPORTS FROM GBOX

export { PATH };

export const HOME_ROUTE: RouteModel = {
  path: PATH.HOME,
  icon: HomeOutlined,
  component: HomeScreen,
};

export const CHAPTER_ROUTE: RouteModel = {
  path: PATH.CHAPTER,
  param: ':bookId/:chapId/:verseId?',
  component: ChapterScreen,
};

export const SEARCH_ROUTE: RouteModel = {
  path: PATH.SERACH,
  param: ':query',
  component: SearchScreen,
};

export const MY_VERSES_ROUTE: RouteModel = {
  path: PATH.MYVERSES,
  component: MyVersesScreen,
};

export const MY_VERSE_ROUTE: RouteModel = {
  path: PATH.MYVERSE,
  param: ':id',
  defaultId: '0',
  component: MyVerseScreen,
};

export const PUBLIC_LIST2: Array<RouteModel> = [HOME_ROUTE, CHAPTER_ROUTE, SEARCH_ROUTE];
export const PRIVATE_LIST: Array<RouteModel> = [MY_VERSES_ROUTE, MY_VERSE_ROUTE];

export const LIST: Array<RouteModel> = [...PUBLIC_LIST2, ...PRIVATE_LIST];

export const ACCOUNT_LIST = [];
export const PUBLIC_LIST = [...GROUTES.PUBLIC_LIST, ...PUBLIC_LIST2];

export const defaultRoute = HOME_ROUTE;
export const DEFAULT_APP_PATH = defaultRoute.path;
