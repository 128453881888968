import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { IonPageWrapper } from './styles';
import { bibleActions } from '$business/redux/bible';
import intl from '$gintl';
import MainHeader from '$components/mainHeader';
import CategoryModel from '$business/models/category';
import { PageWrapper } from '$gstyles/wrapper';
import VerseList from '../chapterScreen/verseList';
import PATH from '$business/enums/paths';

interface MyVerseScreenProps {
  onHydrate;
  onDehydrate;
  currentState;
  category: CategoryModel;
}

const MyVerseScreen: React.FC<MyVerseScreenProps> = ({ currentState, category }) => {
  if (!category) return <IonPageWrapper />;
  const { verses } = category;

  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} titleRoute={PATH.MYVERSES} titleText={intl('ACCOUNT.VERSES')} />
      <IonContent>
        <PageWrapper className="page-wrapper my-verse">
          {verses && verses.length > 0 && <VerseList currentState={currentState} verses={verses} />}
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  category: state.bible.category,
});

const mapDispatchToProps = {
  onHydrate: (param) => bibleActions.getMyCategory(param.id),
  onDehydrate: bibleActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MyVerseScreen));
