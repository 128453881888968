import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Div } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { desktopStyle } from '$gstyles/utils';

export const IonPageWrapper = styled(IonPage)`
  ion-toolbar {
    --min-height: 44px;
  }
  .header-container {
    width: 100%;
    overflow-x: auto;
    height: 44px;
    .container::-webkit-scrollbar {
      display: none;
    }
  }
  .chap-buttons {
    display: flex;
    width: auto;

    .chap-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 44px;
      min-width: 44px;
      max-wdith: 44px;
      border: 1px solid #ccc;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.selected {
        cursor: initial;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
      }
    }
  }
  .chap-links {
    padding-bottom: 16px;
    text-align: center;
    .chap-button {
      cursor: pointer;
      color: var(--ion-color-primary);
      margin: 2px 4px;
      display: inline-block;

      &.selected {
        cursor: initial;
        color: initial;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

  .page-wrapper {
    margin: auto;
    padding: 4px;
  }

  ${desktopStyle(`
    .page-wrapper {
      padding: 16px;
    }
    .check-container {
      display: block !important;
    }
  `)}

  .nav-buttons {
    .nav-button {
      position: fixed;
      top: calc(50% - 22px);
      font-size: 3em;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      :hover {
        opacity: 1;
      }
      &.back {
        left: 0;
      }
      &.forward {
        right: 0;
      }
    }
  }
`;

export const VerseListWrapper = styled(Div)`
  padding-bottom: 100px;
  .verse-line {
    align-items: flex-start;
    justify-content: space-between;
  }
  .MuiCheckbox-root {
    padding: 4px;
  }
  .verse-item {
    flex: 1;
    padding: 4px;
    display: flex;
    .label {
      width: ${(p) => (p.isChapter ? '34px' : '100px')};
      font-size: 0.9em;
      text-align: center;
      padding-right: 4px;
    }
    .content {
      flex: 1;
    }
  }
  .myverse {
    background: #eeffee;
  }
  .selected {
    font-weight: 600;
  }
  ion-fab {
    position: fixed;
  }
`;

export const ModalWrapper = styled(Modal)`
  .select-input {
    margin: 4px;
  }
  .new-input {
    margin: 20px 4px;
  }
`;
