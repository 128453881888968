import _ from 'lodash';
import React, { Component } from 'react';
import intl from '$gintl';
import { IonLoading } from '@ionic/react';

import CellModel from '$gbusiness/models/cell';
import { fetchApi } from '$gbusiness/services/api';

import Table from '../table';
import { sortTable } from '../table/utils';
import VirtualizedTable from '../table/virtualized';
import { NoResult, Wrapper } from './styles';
import {
  DataSourceModel,
  defaultDataSource,
  defaultDisplay,
  defaultStyles,
  TableDisplayModel,
  TableStylesModel,
} from '$gbusiness/models/table';

interface ApiTableProps {
  styles: TableStylesModel;
  dataSource: DataSourceModel;
  filters?: any;
  columns?: any;
  TABLE: Array<CellModel>;
  INNER_TABLE?: Array<CellModel>;
  innerStyles?: TableStylesModel;
  onTableLoad?: Function;
  onSelection?: Function;
  scrollToTop?: Function;
  itemActions?: any;
  resetSelection?: boolean;
}

class ApiTable extends Component<ApiTableProps> {
  state = {
    data: [],
    totalSize: 0,
    isLoaded: false,
    isLoading: false,
    cellPadding: this.props.styles.cellPadding || defaultStyles.cellPadding,
    page: this.props.dataSource.defaultPage || defaultDataSource.defaultPage || 1,
    sortKey: this.props.dataSource.defaultSortKey || defaultDisplay.sortKey,
    sortOrder: this.props.dataSource.defaultSortOrder || defaultDisplay.sortOrder,
  };
  intervalId: any = null;

  componentDidMount = async () => {
    const { autoRefreshInterval, hideInitialLoading = false } = this.props.dataSource;
    if (autoRefreshInterval && autoRefreshInterval > 1) {
      this.intervalId = setInterval(() => {
        this.fetchData(undefined, false, true);
      }, autoRefreshInterval * 1000);
    }
    this.fetchData(undefined, false, hideInitialLoading);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  componentDidUpdate(prevProps) {
    const hasFilterChanged = !_.isEqual(prevProps.filters, this.props.filters);
    const hasTableChanged = prevProps.dataSource && !_.isEqual(prevProps.dataSource, this.props.dataSource);

    if (hasFilterChanged || hasTableChanged) {
      this.fetchData(this.props.filters?.onlyRefresh ? undefined : 1);
    }
  }

  fetchData = async (customPage: any = undefined, appendData = false, forceHideIndicator = false) => {
    const { dataSource, filters, scrollToTop, onTableLoad } = this.props;
    const { endpoint, isPublic, mockData, pageSize, deriveToModel, method, hideLoadingIndicator } =
      dataSource;
    const { isLoading, sortKey, sortOrder, page, data } = this.state;
    const newPage = customPage || page;

    if (!isLoading && !hideLoadingIndicator && !forceHideIndicator) this.setState({ isLoading: true });

    const param: any = {
      ...(dataSource?.defaultParam || {}),
      ...filters,
      sortKey,
      sortOrder,
      page: newPage,
      pageSize,
    };

    const url = method === 'GET' && param ? endpoint + '?' + new URLSearchParams(param).toString() : endpoint;
    const newParam = method === 'GET' ? {} : param;

    const response = await fetchApi({
      url,
      param: newParam,
      isPublic: isPublic,
      ...(method && { method }),
      mockData,
    });

    if (response?.list) {
      const derivedList = deriveToModel ? response.list.map((r) => deriveToModel(r)) : response.list;
      const newData = appendData ? [...data, ...derivedList] : derivedList;
      const totalSize = response.totalSize || response.list.length;
      this.setState({
        page: newPage,
        isLoading: false,
        isLoaded: true,
        data: newData,
        totalSize,
      });
      if (onTableLoad) onTableLoad(totalSize, newData.length, response);
      if (scrollToTop) scrollToTop();
    } else {
      this.setState({ isLoading: false, data: [] });
      if (onTableLoad) onTableLoad(0, 0);
    }
  };

  onLoadNextPage = () => {
    const { pageSize: rowsPerPage } = this.props.dataSource;
    const { totalSize, page } = this.state;
    const pageSize = rowsPerPage === 0 ? totalSize : rowsPerPage || totalSize;
    const numberOfPages = Math.floor((totalSize - 1) / pageSize) + 1;
    if (page >= numberOfPages) return;

    this.fetchData(page + 1, true);
  };

  setPage = (page) => {
    if (page === this.state.page) return;

    this.setState({ page }, this.fetchData);
  };

  pickSelectedRows = (rows) => {
    const { onSelection } = this.props;
    if (!onSelection) return undefined;

    const selectedRows = rows.reduce((acc, value, i) => {
      if (!value) return acc;

      acc.push(this.state.data[i]);
      return acc;
    }, []);

    onSelection(selectedRows);
  };

  getNewData = (key, order) => {
    const { dataSource } = this.props;
    if (dataSource.pageSize) {
      this.fetchData();
      return;
    }

    const newData = sortTable(this.state.data, key, order);
    this.setState({ data: newData });
  };

  // Clicking header column to sort: Only for desktop screen
  onChangeSort = (key, order) => {
    const { sortKey, sortOrder } = this.state;

    if (sortKey === key && sortOrder === order) return;

    // if current sort key is clicked
    if (sortKey === key) {
      this.setState({ sortOrder: order }, () => this.getNewData(key, order));
      return;
    }

    // If different sort key is clicked
    if (order) {
      this.setState({ sortKey: key }, () => this.getNewData(key, order));
      return;
    }

    const newOrder = order || this.props.TABLE.find((c) => c.sortable)?.sortable;
    this.setState({ sortKey: key, sortOrder: newOrder }, () => this.getNewData(key, newOrder));
  };

  render() {
    const {
      TABLE,
      styles,
      dataSource,
      itemActions,
      onSelection,
      columns,
      INNER_TABLE,
      innerStyles,
      resetSelection = false,
    } = this.props;
    const { pageSize, shouldVirtualize, innerKey, onClickRow } = dataSource;
    const { data, isLoading, totalSize, page, sortKey, sortOrder } = this.state;

    if (!totalSize) {
      if (isLoading) return <IonLoading isOpen={true} message={intl('PROGRESS.LOADING')} />;

      return <NoResult className="no-result">{intl('MESSAGE.NO_RESULT')}</NoResult>;
    }

    const display: TableDisplayModel = {
      isSelectable: !!onSelection,
      totalDataSize: totalSize,
      pageSize,
      page,
      sortKey,
      sortOrder: sortOrder === 'ASC' ? 'ASC' : 'DESC',
    };

    const tableProps = {
      display,
      styles,
      TABLE,
      data,
      innerKey,
      onClickRow,
      onChangePage: this.setPage,
      onSelection: onSelection ? this.pickSelectedRows : undefined,
      onChangeSort: this.onChangeSort,
      itemActions,
      columns,
      resetSelection,
    };
    const TableComponent = shouldVirtualize ? (
      <VirtualizedTable {...tableProps} onLoadNextPage={this.onLoadNextPage} />
    ) : (
      <Table {...tableProps} INNER_TABLE={INNER_TABLE} innerStyles={innerStyles} />
    );

    return (
      <Wrapper responsive={styles.responsive}>
        <IonLoading isOpen={isLoading} message={intl('PROGRESS.LOADING')} />
        {TableComponent}
      </Wrapper>
    );
  }
}

export default ApiTable;
