import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Col } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';
import { Modal } from '$gcomponents/reusables';

export const IonPageWrapper = styled(IonPage)`
  .main-section {
    height: 100%;
    max-width: 1200px;
    justify-content: flex-start;
    align-items: flex-start;
    .books {
      flex: 1;
      display: block;
      overflow-y: auto;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      > div {
      }
    }
    .books.old {
      border-right: 1px solid #ccc;
    }
  }
`;

export const BookItem = styled(Col)`
  cursor: pointer;
  display: Flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #ccc;
  margin-top: -1px;
  margin-left: -1px;
  width: calc(25% + 1px);
  > div {
    padding: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${mobileStyle(`
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    border-left: none;
    border-top: none;
  `)}
`;

export const ModalWrapper = styled(Modal)`
  .grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(52px, 1fr));
    grid-gap: 0rem;

    .chap-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 52px;
      border: 1px solid #ccc;
      margin-top: -1px;
      margin-left: -1px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  @media screen and (max-width: 600px) {
    .grid-view {
      display: flex;
      flex-flow: row wrap;
      .chap-button {
        width: calc(20% + 1px);
      }
  }

`;
