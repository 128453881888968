import React, { useEffect } from 'react';
import ChapterModel from '$business/models/chapter';
import { intToArray } from '$gbusiness/helpers/util';
import { IonHeader } from '@ionic/react';

interface ChapButtonsProps {
  chapter: ChapterModel;
  onSelectChapter: Function;
  isDesktop?: boolean;
}

const ChapButtons: React.FC<ChapButtonsProps> = ({ isDesktop = false, chapter, onSelectChapter }) => {
  const numVerses = chapter.book.numChapters;

  useEffect(() => {
    if (isDesktop) return;
    scrollToChapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter.uid]);

  const scrollToChapter = () => {
    const chapId = chapter?.chapter || 0;
    const chapPosition = 44 * (chapId - 1);
    const deviceMiddleX = Math.round(window.innerWidth / 2);
    const scrollX = chapPosition - deviceMiddleX + 22;
    const container = document.querySelector('.header-container');
    if (!container) return;
    container.scrollTo(scrollX, 0);
  };

  const links = intToArray(numVerses).map((i) => {
    const chap = i + 1;
    const isCurrentChap = chapter.chapter === chap;
    const buttonProps = {
      className: `chap-button ${isCurrentChap && 'selected'}`,
      ...(!isCurrentChap && { onClick: () => onSelectChapter(chap) }),
    };
    return (
      <div key={i} {...buttonProps}>
        {chap}
      </div>
    );
  });

  return isDesktop ? (
    <div className="chap-links">{links}</div>
  ) : (
    <div className="header-container">
      <IonHeader>
        <div className="chap-buttons">{links}</div>
      </IonHeader>
    </div>
  );
};

export default ChapButtons;
