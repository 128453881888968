import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';

import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser, deriveRawToUser, LoginModel } from '$gbusiness/models/user';
// import { handleApiFail } from '$gbusiness/services/api';

import { AppModel } from '../';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import { LOGGING_IN, LOGIN_SUCCESS } from '$gbusiness/redux/auth/types';
import { handleFail, storeAuth } from '$gbusiness/redux/auth/actions';
import { deriveRawToBook } from '../../models/book';
import { FETCH_BOOKS_SUCCESS, FETCH_CATEGORIES_SUCCESS } from '../bible/types';
import { INIT_SUCCESS } from './types';
import { deriveRawToCategory } from '../../models/category';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();
  const settings = storageData.settings ? JSON.parse(storageData.settings) : null;

  dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
      settings,
    },
  });
}

async function authToken(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.token,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user) {
    localStorage.clearAuth();
  } else {
    const user: UserModel = deriveRawToUser(response.user) || defaultUser;

    dispatch({
      type: SET_CUSER,
      user,
    });
  }
  if (response && response.books) {
    dispatch({
      type: FETCH_BOOKS_SUCCESS,
      books: response.books.map(deriveRawToBook),
    });
    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories: response.cats.map(deriveRawToCategory),
    });
  }
}

export function loadApp(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    await loadStorage(dispatch);

    // Load Theme
    const { localStorage } = getState();

    restoreTheme(localStorage.theme);

    await authToken(dispatch);

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}

export function login(param: LoginModel): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await apiService.fetchApi({
      url: configs.api.login,
      param: {
        ...param,
        factoryId: getPublicFactoryId(),
      },
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.user) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    dispatch({
      type: LOGIN_SUCCESS,
      accessToken: response.accessToken,
      user,
    });

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories: response.cats.map(deriveRawToCategory),
    });
  };
}
