import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import { bibleActions } from '$business/redux/bible';
import intl from '$gintl';
import MainHeader from '$components/mainHeader';
import { Col, Row } from '$gstyles';
import CategoryModel from '$business/models/category';
import { PageWrapper } from '$gstyles/wrapper';
import { Link } from '$gcomponents/primitives';
import PATH from '$business/enums/paths';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { dialog } from '$gcomponents/reusables';

interface MyVersesScreenProps {
  onHydrate;
  onDehydrate;
  currentState;
  categories: Array<CategoryModel>;
}

const MyVersesScreen: React.FC<MyVersesScreenProps> = ({ currentState, categories }) => {
  const dispatch = useDispatch();
  const onDeleteCat = (id) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: () => {
        dispatch(bibleActions.removeCat(id));
      },
    });
  };
  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} titleText={intl('ACCOUNT.VERSES')} />
      <IonContent>
        <PageWrapper className="page-wrapper">
          <Row>
            {categories.map((cat, i) => {
              return (
                <Col key={i} className="cat flex" gridSize={3}>
                  <Link
                    className="link"
                    route={`${PATH.MYVERSE}/${cat.id}`}>{`${cat.name} (${cat.numVerses})`}</Link>
                  <Delete className="delete-icon" onClick={() => onDeleteCat(cat.id)} />
                </Col>
              );
            })}
          </Row>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  categories: state.bible.categories,
});

const mapDispatchToProps = {
  onHydrate: bibleActions.getMyCategories,
  onDehydrate: userActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MyVersesScreen));
